@use '@sats-group/ui-lib/tokens/font-names';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/spacing';
@use 'sass:color';

.time-input {
  $block: &;
  $vertical-padding: 13px;
  $line-height: 1.25;

  &__label {
    margin-bottom: spacing.$xs;
    padding-left: spacing.$m;
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
  }

  &__input {
    border: 1px solid light.$ge-divider-default;
    border-radius: 8px;
    padding: $vertical-padding spacing.$m;
    font-size: 16px;
    font-family: font-names.$brand, sans-serif;
    line-height: $line-height;
    background-color: light.$surface-primary-default;
    color: light.$on-surface-primary-default;
    width: 100%;
    box-sizing: border-box;

    &:focus {
      outline: 2px solid light.$ge-divider-default;
      outline-offset: -2px;
    }

    &[disabled] {
      background-color: light.$surface-secondary-default;
      border-color: light.$ge-divider-alternate;
    }

    @at-root {
      #{$block}--error & {
        background-color: color.mix(
          light.$ge-signal-error,
          white,
          7%
        ); // NOTE: Undocumented background color
        border-color: light.$ge-signal-error;

        &[disabled] {
          background-color: light.$ge-divider-default;
        }
      }
    }
  }

  &__input[type='date'] {
    appearance: textfield;
    min-height: 46px;
  }
}
